import { TitleH3 } from 'components/Texts';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import useI18n from 'i18n/useI18n';
import { getSchedule, getSpecialDates } from '../../Edit/parseFormData';
import { dateFormat, DATE_FORMAT } from 'utils/date';
import { TitleH4 } from 'components/Texts';
import { Body } from 'components/Texts';

const Container = styled.div`
  display: grid;
  grid-template-rows: 2rem minmax(0, 1fr);
  height: 100%;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
`;

const ScheduleTime = ({ title, time }) => {
  return (
    <div>
      <TitleH4>{title}</TitleH4>
      <div style={{ height: '5px' }}></div>
      {validValue(time) && <Body>{`${time.hour}:${time.minute} ${time.time}`}</Body>}
    </div>
  );
};

const today = new Date();

const validValue = (value) => !!value.hour && !!value.minute && !!value.time;

const Schedule = ({ group }) => {
  const { translate, formatDate } = useI18n();
  const [time, setTime] = useState(null);
  const [open, setOpen] = useState(null);

  useEffect(() => {
    const config = group.config ? JSON.parse(group.config) : {};
    const schedule = getSchedule(config);
    const specialDates = getSpecialDates(config);

    if (!!schedule) {
      if (
        !!specialDates &&
        specialDates.some(({ date }) => dateFormat(date, DATE_FORMAT) === dateFormat(new Date(), DATE_FORMAT))
      ) {
        const date = specialDates.find(({ date }) => dateFormat(date, DATE_FORMAT) === dateFormat(new Date(), DATE_FORMAT));
        setTime(date && date.isOpen && date.hours.some((hour) => validValue(hour)) ? date.hours : null);
        setOpen(date ? date.isOpen : null);
        return;
      }
      const time = schedule[dateFormat(new Date(), 'EEEE').toLowerCase()];
      setTime(time && time.isOpen && time.hours.some((hour) => validValue(hour)) ? time.hours : null);
      setOpen(time ? time.isOpen : null);
    }
  }, [group]);

  return (
    <Container>
      <TitleH3>{`${translate('Schedule')} (${formatDate(today)})`}</TitleH3>
      <Grid>
        {time ? (
          <>
            <ScheduleTime title={translate('Opening')} time={time[0]} />
            <ScheduleTime title={translate('Mid')} time={time[1]} />
            <ScheduleTime title={translate('Close')} time={time[2]} />
          </>
        ) : open ? (
          <Body>{translate('Schedule for today not defined yet')}</Body>
        ) : (
          <Body>{translate('Closed')}</Body>
        )}
      </Grid>
    </Container>
  );
};

export default Schedule;
